<template>
    <div>
        <div v-for="i in $props.data" :key="i.id" class="boxes">
            <div class="cell-boxes">
                <div class="flex align-center">
                    <span class="title">{{ i.coin.unit }}</span>
                    <van-icon v-if="eyeVisible" @click="replaceAmount" name="eye-o" color="#616E85" size="20" />
                    <van-icon v-else @click="restoreAmount" name="closed-eye" color="#616E85" size="20" />
                </div>
                <div class="align-center mt15">
                    <div class="flex-one mr3">
                        <span class="label" style="margin-top: -12px;">{{ $t("assets.index.normal") }}</span>
                        <div class="num">
                            {{ eyeVisible ? i.balance ? i.balance.toFixed(6) : "000000" : "********" }}
                        </div>
                    </div>
                    <div class="flex-one">
                        <span class="label">{{ $t("assets.index.freeze") }}</span>
                        <div class="num">
                            {{ eyeVisible ? i.frozenBalance ? i.frozenBalance.toFixed(6) : "000000" : '********' }}
                        </div>
                    </div>
                </div>
                <img src="../../../assets/img/point.png" alt="" v-if="i.coin.unit == 'USDT'"
                    style="width: 20px;height: 20px;position: absolute;top: 19px;right: 16px;" @click="i.coin.unit == 'USDT' ? transfer(i) : ''">
                <!-- <div v-if="$props.tabIndex > 0" class="flex align-center mt15">
                    <div class="flex-one mr3">
                        <span class="label">{{ $t("assets.index.trial_fee") }}</span>
                        <div class="num" data-original="97243.40">
                            {{
                                i.trialfundsBalance ? i.trialfundsBalance.toFixed(6) : "000000"
                            }}
                        </div>
                    </div>
                    <div class="flex-one">
                        <span class="label">{{
                            $t("assets.index.experience_gold_freeze")
                        }}</span>
                        <div class="num" data-original="97243.30">
                            {{
                                i.trialfundsFrozenBalance
                                ? i.trialfundsFrozenBalance.toFixed(6)
                                : "000000"
                            }}
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
        <van-action-sheet v-model="sheetShow" @select="selectSheet" :description="$t('other.translate77')"
            :actions="actions[$props.tabIndex]" :cancel-text="$t('button.otc.cancel')" close-on-click-action />
    </div>
</template>
<script>
import store from "../store/common";

export default {
    data() {
        return {
            eyeVisible: true,
            sheetShow: false,
            actions: {
                0: [
                    {
                        name: this.$t("assets.index.n3"),
                        value: "contract",
                        type: "transfer",
                    },
                ],
                1: [
                    {
                        name: this.$t("assets.index.n1"),
                        type: "recharge",
                    },
                    {
                        name: this.$t("assets.index.n2"),
                        type: "withdraw",
                    },
                    {
                        name: this.$t("assets.index.n3"),
                        value: "bibi",
                        type: "transfer",
                    },
                ],
            },
        };
    },
    props: ["data", "tabIndex"],
    methods: {
        replaceAmount() {
            // const elements = document.querySelectorAll(".num");
            // elements.forEach((element) => {
            //     const content = element.innerText;
            //     const replacedContent = content.replace(/[\d.]/g, "*");
            //     element.innerText = replacedContent;
            // });

            this.eyeVisible = false;
        },
        restoreAmount() {
            // const elements = document.querySelectorAll(".num");
            // elements.forEach((element) => {
            //     const originalContent = element.getAttribute("data-original");
            //     element.innerText = originalContent;
            // });

            this.eyeVisible = true;
        },
        selectSheet(val) {
            // 划转
            if (val.type == 'transfer') {
                store.commit("setTopType", val.value);
                this.$router.push({ name: "transfer" });
            }
            // 提币
            if (val.type == 'withdraw') {
                this.$router.push({ name: "withdrawal" });
            }
            // 充值
            if (val.type == 'recharge') {
                this.$router.push({ name: "deposit" });
            }
        },
        transfer(coin) {
            store.commit("setCurrency", coin);
            this.sheetShow = true;
        },
    },
};
</script>

<style>
.mt15 {
    margin-top: 3.8168vw;
}

.darkPage .boxes {
    width: 100%;
    position: relative;
}

.flex {
    display: flex;
    align-items: center;
}

.darkPage .boxes .cell-boxes {
    width: 100%;
    height: 159px;
    border: 1px solid #000000;
    border-radius: 16px;
    padding: 15px 0 0 28px;
    margin-bottom: 16px
}

/* .darkPage .boxes .cell-boxes::after {
    content: "";
    position: absolute;
    top: 5%;
    right: -6%;
    width: 29.5165vw;
    height: 26.2087vw;
    background: url("../../../assets/img/sign-bg.png") no-repeat;
    background-size: 100% 100%;
    transform: scale(1.5);
} */

.darkPage .boxes .cell-boxes .title {
    color: #000;
    font-size: 18px;
    font-weight: 600;
    margin-right: 2.0356vw;
    border-left: 4px solid #3AD29F;
    padding-left: 8px;
    display: inline-block;
    height: 16px;
    line-height: 16px;

}

.darkPage .boxes .cell-boxes .label {
    font-family: SF Pro;
    font-size: 12px;
    font-weight: 510;
    color: #00000080;
    margin-top: 12px;
    display: inline-block
}

.darkPage .boxes .cell-boxes .num {
    /* margin-top: 2.5445vw; */
    font-family: SF Pro;
    font-size: 14px;
    font-weight: 600;
    line-height: 100%;
    color: #0d1421;
}

.van-action-sheet__cancel:before {
    display: none;
}
</style>