<template>
    <div>
        <navbar :params="pageInfo">
        </navbar>
        <div style="width: 100%;background-color: #E6E6E6;height: 1px;"></div>
        <div class="flexPage darkPage">
            <div class="tag">
                <div class="tagBox" :class="{ 'active': chooseNum == index }" v-for="(item, index) in tabsList"
                    @click="choose(index)">{{ item }}</div>
            </div>
            <template v-if="chooseNum == 0">
                <assetsList :tabIndex="chooseNum" :data="contractData" />
            </template>
            <template v-if="chooseNum == 1">
                <assetsList :tabIndex="chooseNum" :data="currencyData" />
            </template>
        </div>

        <!-- <van-tabs :border="false" sticky title-active-color="#3861FB" title-inactive-color="#616E85" color="#3861FB"
            line-height="2px" @change="onTabChange">
            <van-tab v-for="(item, index) in tabsList" :key="item" :title="item">

                <template v-if="index == 0">
                    <assetsList :tabIndex="index" :data="otcData" />
                </template>

                <template v-if="index == 1">
                    <assetsList :tabIndex="index" :data="contractData" />
                </template>

                <template v-if="index == 2">
                    <assetsList :tabIndex="index" :data="currencyData" />
                </template>
            </van-tab>
        </van-tabs>  -->
    </div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue';
import assetsList from './components/assetsList.vue';

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                border: false,
                customTitle: this.$t('assets.personal.my_assets'),
                navbarStyle: 'normal',
                backname: "my",
                value: '',
                icon: 'todo-list-o',
                url: 'assetRecord'
            },
            // tabsList: [this.$t('assets.index.otc_account'), this.$t('assets.index.contract_account'), this.$t('assets.index.a1')],
            tabsList: [this.$t('assets.index.contract_account'), this.$t('assets.index.a1')],
            currencyData: [],// 币币资产
            otcData: [], //OCT资产
            contractData: [], // 合约资产
            chooseNum: 0
        }
    },
    components: {
        navbar,
        assetsList
    },
    mounted() {
        this.getContractData();
    },
    methods: {
        // 切换
        choose(val) {
            this.chooseNum = val
            if (val === 0) {
                this.getContractData();
            } else if (val === 1) {
                this.getCurrencyData();
            }
        },
        // 获取OTC账户
        getOtcData() {
            this.$http.post(this.host + '/uc/otc/wallet/get').then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.otcData = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        // 获取合约账户
        getContractData() {
            this.$http.post(this.host + '/uc/asset/wallet').then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.contractData = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        // 获取币币账户
        getCurrencyData() {
            this.$http.post(this.host + '/uc/asset/spotWallet').then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.currencyData = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        }
    }
}

</script>

<style scoped>
.tagBox {
    width: 156px;
    height: 36px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    line-height: 36px;
    text-align: center;
}

.active {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
}

.tag {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 12px 0;
    background: #F6F6F6;
    border-radius: 8px;
}

.flex {
    display: flex;
}

.flex-one {
    flex: 1;
}

.align-center {
    align-items: center;
}

.darkPage {
    min-height: 100vh;
    height: auto;
    background: #fff;
    padding: 0 16px
}

.van-tabs__content {
    background: #F8FAFD;
}
</style>