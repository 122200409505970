import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        currency: {},
        topType: '',
    },
    mutations: {
        setCurrency(state, value) {
            state.currency = value;
        },
        setTopType(state, value) {
            state.topType = value;
        }
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
});

export default store;